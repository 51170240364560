.page-back
{
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: row;
    height: calc(var(--vh, 1vh) * 100);
}

.cover-box
{
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100px;
    height: 100px;
    padding: 0px;
    background-color: #ffffff;
    border-radius: 5px;
    width: 90vw;
    height: calc(var(--vh, 1vh) * 75);
    max-width: 1024px;
    max-height: 853px;
    overflow: hidden;
    box-shadow: 0 2px 8px 2px rgba(0,0,0,0.3);
}
.DayPicker_weekHeader_li {
  margin-left: 0px;
  font-family: 'Poppins';
}

.CalendarDay__highlighted_calendar {
  background: #ED485F;
  color: #ffffff;
}

.CalendarDay__highlighted_calendar:hover {
  background: #bf3c4e;
  color: #ffffff;
}

.DateInput {
  width: 75%;
}

.DateInput_input {
  font-family: 'Poppins';
  font-weight: 400;
}

.CalendarMonth_caption {
  font-family: 'Poppins';
}

/* Sweetalert styling */
.swal-footer {
  text-align: center;
}
.swal-modal {
  font-family: 'Poppins';
}
.swal-custom-btn {
  background-color: #f44336;
}

/* Account Menu */
.account-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-color: rgba(0,0,0,0.6);
  background-blend-mode: overlay;
  background-position: center;
  overflow: auto;
  height: calc(var(--vh, 1vh) * 75);
}
.account-menu-outer-container {
  width: 100%;
  margin-top: 80px;
  margin-bottom: 20px;
}
.account-menu-container {
  padding: 5px 15px 15px 15px;
}
.account-menu-section-title {
  font-weight: 500;
  font-weight: 14px;
  font-family: 'Poppins';
  font-size: 15px;
  margin-bottom: 5px;
  margin-top: 8px;
}
.account-menu-section-container {
  border: 1px solid #e7e7e7;
  border-radius: 3px;
}
.account-menu-section-container.sign-out {
  margin-top: 15px;
  border: none;
  background-color: #f44336;
  border-radius: 3px;
  color: #ffffff;
  font-weight: 400;
  font-size: 13px;
  font-family: 'Poppins';
}
.sign-out > .account-menu-section-item:hover {
  background-color: #e51000!important;
}
.account-menu-section-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 10px;
  cursor: pointer;
}
.account-menu-section-item:hover {
  background-color: #f7f7f7;
}
.account-menu-section-item-title {
  font-weight: 400;
  font-size: 13px;
  font-family: 'Poppins';
}

.account-menu-version {
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  font-family: 'Poppins';
  color: #a5a5a5;
  margin-top: 10px;
}

.MuiCircularProgress-svg {
  margin: 0!important;
}

.business-location-selector {
  padding: 10px;
}

@media screen and (min-width: 1200px) and (max-height: 700px) {
  .cover-box {
    height: calc(var(--vh, 1vh) * 100);
  }
  .left {
    height: calc(var(--vh, 1vh) * 100);
  }
  .account-menu {
    height: calc(var(--vh, 1vh) * 100)!important;
  }
  .page-button-next {
    position: fixed;
    bottom: 0;
    width: 55%;
  }
  .booked-img {
    height: calc(var(--vh, 1vh) * 100);
  }
  .landing-terms-container {
    max-height: calc(var(--vh, 1vh) * 100 - 230px);
  }
  .right {
    height: calc(var(--vh, 1vh) * 100);
  }
  .account-menu-outer-container {
    margin-top: 190px;
  }
  .loading-container {
    height: calc(var(--vh, 1vh) * 100);
  }
}

/* On screens that are 1200px or less, set the background color to blue */
@media screen and (max-width: 1200px) {
  .cover-box {
    width:100vw;
    height: 100%;
    border-radius: 0px;
    max-width: 100vw;
    max-height: 100%;
    min-height: 100%;
  }
  .left {
    height: calc(var(--vh, 1vh) * 100);
    /*scroll-behavior: smooth;*/
  }
  .page-button-next {
    position: fixed;
    bottom: 0;
    width: 55%;
  }
  .booked-img {
    height: calc(var(--vh, 1vh) * 100);
  }
  .landing-terms-container {
    max-height: calc(var(--vh, 1vh) * 100 - 230px);
  }
}
/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
.cover-box {
  width:100vw;
  height: 100%;
  border-radius: 0px;
  max-width: 100vw;
  max-height: 100%;
  min-height: 100%;
}
.left {
  height: calc(var(--vh, 1vh) * 100);
}
.page-button-next {
  position: fixed;
  bottom: 0;
  width: 100%;
}
.booked-img {
  height: calc(var(--vh, 1vh) * 100);
}
.account-menu-outer-container {
  margin-top: 20px;
}
}
@media screen and (max-width: 992px) and (max-height: 750px){
  .account-menu-outer-container {
    margin-top: 200px;
  }
}