/* LANDING PAGE */
@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600');

.landing-title{
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins';
    text-align: center;
    font-weight: 400;
    font-size: 30px;
    overflow: hidden;
}

.landing-logo {
    vertical-align: middle;
}

.business-logo {
    vertical-align: middle;
    height: 140px;
}

.business-logo-side {
    vertical-align: middle;
    height: 100px;
    display: block;
    position: relative;
    margin: 0 auto;
    top: 40%;
}

.business-status-indicator {
    width: 14px;
    height: 14px;
    background-color: #4caf50;
    display: inline-block;
    border-radius: 7px;
    margin-right: 10px;
    animation: pulse 1.5s infinite;
}

@keyframes pulse {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
        opacity: 0;
    }
  }

  #cartCloseBtn, #cartToggleBtn {
      display: none;
  }

.landing-img{
    vertical-align: middle;
    width: 100%;
    font-family: 'Poppins';
    font-weight: 300;
    text-align: center;
    font-size: 30px;
    color: #ffffff;
    overflow: hidden; 
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-blend-mode: overlay;
    background-position: center;
    background-size: cover;
}

.landing-img-overlay {
    display: flex;
    flex: 1;
    background-color: rgba(0,0,0, 0.6);
}

.landing-img-text{
    margin: auto;
    font-weight: 400;
}

.landing-img-text-bold{
    font-weight: 600;
    font-size: 20px;
}

.landing-footer{
    height: 100px;
    vertical-align: middle;
    width: 100%;
    text-align: center;
    background-color: #ffffff;
    overflow: hidden;
    font-family: 'Poppins';
    font-weight: 400;
    text-align: center;
    font-size: 20px;
}

.landing-footer-title {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
}

.landing-footer-button-group {
    display: flex;
    margin: auto;
}

.landing-terms-container {
    padding: 15px;
    max-height: calc(var(--vh, 1vh) * 75 - 230px);
    overflow-y: auto;
    font-family: 'Poppins';
    flex: 1;
}

.line{
        display: block;
        height: 2px;
        border: 0;
        width: 40%;
        border-top: 2px solid #ffffff;
        margin: 1em auto;
        padding: 0;
}

.overlay-line{
    display: block;
    height: 2px;
    border: 0;
    width: 100%;
    border-top: 2px solid #ffffff;
    margin: 1em auto;
    padding: 0;
}

/* FIRST PAGE */

.wrapper {
    position: relative;
    height: 100%;
}

.left {
    height: 100%;
    float: left;
    width: 55%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    /*height: calc(var(--vh, 1vh) * 75);*/
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
  }

.loading-container {
    position: absolute;
    width: 55%;
    height: calc(var(--vh, 1vh) * 75);
    background-color: rgba(0,0,0,0.55);
    z-index: 10;
}

.right {
    display: flex;
    align-items: center;
    justify-content: center;
    float: right;
    height: calc(var(--vh, 1vh) * 75);
    width: 45%;
    background-size: cover;
    background-color: rgba(0,0,0,0.6);
    background-blend-mode: overlay;
    background-position: center;
    overflow: auto;
}

.right-back-icon {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    padding: 15px;
    cursor: pointer;
}

.page-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Poppins';
    font-weight: 400;
    text-align: left;
    font-size: 20px;
    background-color: white;
    z-index: 1;
    padding: 0px 10px;
    position: sticky;
    top: 0;
    min-height: 40px;
}

.page-title-line{
    width: 97%;
    margin-left: 1.5%;
    margin-right: 1.5%;
    border-bottom: 2px solid #dadada;
}

.page-button-next{
    height: 50px;
    line-height: 50px;
    vertical-align: middle;
    border-width: 0;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 20px;
    overflow: hidden;
    padding-left: 8px;
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: black;
    color: white;
    z-index: 1;
    text-align: center;
}

.services-container {
    height: calc(100% - 50px);
}

.service-header{
    height: 50px;
    line-height: 50px;
    vertical-align: middle;
    /*border-bottom: 2px solid black;*/
    font-family: 'Poppins';
    font-weight: 400;
    text-align: left;
    font-size: 19px;
    overflow: hidden;
    padding-left: 8px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 100%;
}

.cart-item-container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    padding-left: 8px;
    position: relative;
    padding: 12px 5px;
    line-height: 18px;
    font-size: 16px;
    font-family: 'Poppins';
    font-weight: 500;
}

.service:hover{
    background-color:#F8F8F8;
 }

 .service-active {
    /*background-color: #D13900;*/
    /*background-color: #007777;*/
    background-color: #1ab394;
    color: white;
    box-shadow: 0 1px 15px 1px rgba(62,57,107,.8);
 }

 .service-active:hover{
    /*background-color:#E94000;*/
    /*background-color: #198484;*/
    background-color: #179178;
 }

 .service-line, .card-line{
    width: 97%;
    margin-left: 1.5%;
    margin-right: 1.5%;
    border-bottom: 1px solid #dadada;
}

.card-line {
    width: 93%;
    padding: 10px;
    font-size: 16px;
    font-family: 'Poppins';
    font-weight: 500;
}

.service-info {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
    align-self: center;
    font-family: 'Poppins';
}

.service-description {
    color: #707070;
    font-size: 11px;
}

.service-description-active {
    color: #ffffff;
    font-size: 11px;
}

.service-title {
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    flex-grow: 1;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 17px;
    margin-left: 15px;
}

.sticky-next-button-container {
    position: sticky;
    bottom: 0;
}

.cart-item-title {
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    flex-grow: 1;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 15px;
    text-align: left;
}

.cart-item-price {
    font-size: 15px;
}

.service-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 16px;
    margin-right: 15px;
}

.service-spinner {
    margin-top: 20px;
}

.service-detail-master-containter {
    box-shadow: inset 0px 11px 8px -10px #BBBBBB, inset 0px -11px 8px -10px #BBBBBB; 
}

.service-detail-container {
    padding: 10px;
    cursor: pointer;
    border-top: 1px dashed #b5b5b5;
}

.service-detail-container:first-child {
    border-top: 0px;
}

.service-detail-container:hover{
    background-color: #E8E8E8;
 }

.service-detail-info {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
    align-self: center;
    font-family: 'Poppins';
}

.service-detail-title {
    display: flex;
    flex-direction: row;
    flex-shrink: 1;
    flex-grow: 1;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 14px;
}

.service-detail-title-img {
    border-radius: 50%;
    vertical-align: middle;
}

.service-detail-right {
    display: flex;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 14px;
}

.service-detail-description {
    font-size: 11px;
    font-family: 'Poppins';
    font-weight: 500;
    color: #707070;
}

.service-detail-line{
    width: 100%;
    border-bottom: 1px dashed #dadada;
}

.staff-spinner {
    margin-top: 100px;
}

.card-item-description {
    padding: 9px 10px;
    width: 25%;
    margin-right: 10px;
    text-align: left;
}

.card-item {
    padding: 9px 10px;
    text-align: center;
    display: inline-block;
}
/* Cart */

.cart-container {
    background-color: #ffffff;
    border-radius: 5px;
    max-height: 80%;
    width: 75%; 
    max-width: 385px;
    margin: 0px auto 24px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(var(--vh, 1vh) * 24);
    padding: 0px 10px;
    overflow: auto;
}

.cart-header {
    position: sticky;
    top: 0;
    background-color: #ffffff;
    padding-top: 10px;
    z-index: 2;
}

.cart-title
{
    text-align: center;
    width: 100%;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 20px;
}

.cart-business
{
    text-align: center;
    width: 100%;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 15px;
    border-bottom: 1px solid #bfbfbf;
    padding-bottom: 6px;
}

.cart-empty
{
    display: flex;
    flex: 1;
    justify-content: center; /* align horizontal */
    align-items: center; /* align vertical */
    font-family: 'Poppins';
    font-size: 15px;
    font-weight: 400;
}

.no_times_available
{
    display: flex;
    flex: 1;
    justify-content: center; /* align horizontal */
    align-items: center; /* align vertical */
    font-family: 'Poppins';
    font-size: 20px;
    font-weight: 500;
    white-space: nowrap;
}

.times_container
{
    flex: 1;
    overflow-y: auto;
}

.time-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 15px;
    font-size: 16px;
    font-family: 'Poppins';
    cursor: pointer;
}

.time-container:hover {
    background-color:#F8F8F8;
}

/* SECOND PAGE */

.staff {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    padding-left: 8px;
    border-bottom: 1px solid #bfbfbf;
    position: relative;
    padding: 25px 8px;
    line-height: 18px;
    font-size: 14px;
    font-family: 'Poppins';
    font-weight: 500;
}

.staff:hover{
    background-color:#bababa;
 }

 .staff-info {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
    align-self: center;
}

.staff-name {
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    flex-grow: 1;
}

.staff-position {
    color: #707070;
}

/* Staff picker component */

.staff-picker-header {
    z-index: 2;
    background-color: #ffffff;
    position: sticky;
    top: 0;
    padding: 16px 24px;
    text-align: center;
    font-family: 'Poppins';
    font-weight: 500;
}

.staff-picker-item {
    display: flex;
    flex-direction: row;
    padding: 10px 15px;
    cursor: pointer;
}

.staff-picker-item:hover {
    background-color:#F8F8F8;
}

/* THIRD PAGE */

/* SIXTH PAGE */

.booked-img{
    height: calc(var(--vh, 1vh) * 75);
    /*line-height: 55vh;*/
    vertical-align: middle;
    width: 100%;
    font-family: 'Poppins';
    font-weight: 400;
    text-align: center;
    font-size: 30px;
    color: #ffffff;
    background-size: cover;
    background-position: fixed;
    overflow: hidden; 
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: rgba(0,0,0,0.6);
    background-blend-mode: overlay;
}

.booked-img-text{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 20%;
    margin: auto;
    line-height: 30px;
}

.booked-img-text-title{
    font-size: 35px;
    font-weight: 300;
}

.booked-img-text-bold{
    font-weight: 600;
    font-size: 20px;
}

.booked-img-text-light{
    font-weight: 300;
    font-size: 18px;
    padding-top: calc(var(--vh, 1vh) * 5);
}

.booked-img-powered{
    font-weight: 500;
    font-size: 15px;
    padding-top: calc(var(--vh, 1vh) * 10);
}

.book-button-header {
    display: none!important;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 0px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: grey; 
    border-radius: 0px;
  }

@media screen and (max-width: 1200px) {
    .left {
        height: calc(var(--vh, 1vh) * 100);
    }
    .loading-container {
        height: calc(var(--vh, 1vh) * 100);
    }
    .right {
        height: calc(var(--vh, 1vh) * 100);
    }
    .account-menu {
        height: calc(var(--vh, 1vh) * 100)!important;
    }
    .user-container {
        width: 50%!important;
    }
    .user-container-footer {
        width: 50%!important;
    }
    .business-logo {
        height: 105px;
    }
}



/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
    .left {
      width:100%;
    }
    .loading-container {
        width: 100%;
        height: calc(var(--vh, 1vh) * 100);
    }
    
    #cartCloseBtn, #cartToggleBtn {
        display: inline-flex;
    }

    .right {
        display: none; /* Hidden by default */
        position: fixed; /* Stay in place */
        z-index: 1; /* Sit on top */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    }
    .right-back-icon {
        display: block;
    }
    .hidden {
        display: none;
    }
    .show{
        display: flex;
    }
    .user-container {
        width: 70%!important;
    }
    .user-container-footer {
        width: 70%!important;
    }
    .business-logo {
        height: auto;
        width: 50%;
    }
    .book-button-header {
        display: block!important;
    }
    .overlay-line {
        width: 60%;
    }
}

@media screen and (max-width: 670px) {
    .user-container {
        width: 90%!important;
    }
    .user-container-footer {
        width: 90%!important;
    }
}

@media screen and (max-width: 500px) {
    .business-logo {
        height: auto;
        width: 70%;
    }
    .overlay-line {
        width: 70%;
    }
    .user-container-sm {
        width: 100%!important;
    }
}

/* FORM GENERIC */

.group {    
    display: block;
    font-family: 'Poppins';
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

.input-container {
    max-width: none;
}

.label-generic {
    /*padding-left: 0px;
    padding-right: 0px;*/
    color: #000000;
    font-family: 'Poppins';
    font-weight: 500;
}

.terms-label-generic {
    /*padding-left: 0px;
    padding-right: 0px;*/
    color: #000000;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 13px;
    padding: 15px 11.25px;
}

.terms-link {
    color: #ff2b54;
    font-weight: 600;
}

.label-heavier {
    font-weight: 700;
}

.input-generic {
    border: 0px;
    border-bottom: 1px solid #828488;
    font-family: 'Poppins';
    font-size: 20px;
    font-weight: 300;
    padding-left: 0px;
    padding-right: 0px;
}

.input-generic:focus { 
    border-bottom: 2px solid #57585a;
    font-weight: 500;
}

.checkbox-generic {
}

a {
    text-decoration:none;
    color: #ffffff;
}

/* DATE PICKER UI */

.SingleDatePicker {
    width: 100%;
    font-family: 'Poppins';
}

.SingleDatePicker_picker {
    z-index: 3!important;
}

.SingleDatePickerInput {
    width: 100%;
    border-radius: 0px!important;
    border: 0!important;
    border-bottom: 1px solid #dbdbdb!important;
}

.SingleDatePickerInput_calendarIcon {
    margin: 0px 0px -3px 0px!important;
    border: 0!important;
}

.SingleDatePicker_closeButton_svg {
    fill: rgb(244, 67, 54)!important;
}

.DateInput_input {
    cursor: pointer;
    display: flex;
    flex: 1;
    border: 0!important;
    padding: 11px 5px 8px!important;
}

.DateInput_input__focused {
    border: 0!important;
}

.DayPickerNavigation__verticalDefault {
    display: flex;
}

.CalendarDay__selected {
    background: #192027!important;
    border: 1px double #192027!important;
    font-weight: 600;
}

.CalendarDay__default {
    font-family: 'Poppins';
}

/* TOTAL BOX */

.total-container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    padding: 15px 5px;
    line-height: 18px;
    font-size: 14px;
    font-family: 'Poppins';
    font-weight: 500;
    border-top: 2px solid #525252;
    position: sticky;
    bottom: 0;
    z-index: 2;
    background-color: #ffffff;
}

.total-info {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
    align-self: center;
    font-family: 'Poppins';
}

.total-title {
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    flex-grow: 1;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 17px;
    text-align: left;
}

.total-price {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 16px;
}

/* DEPOSIT BOX */
.deposit {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    padding: 0px 5px 5px 5px;
    line-height: 18px;
    font-size: 14px;
    font-family: 'Poppins';
    font-weight: 500;
}

/* STRIPE ELEMENTS CONTAINER */
.StripeElement {
    width: 100%;
}

.new-payment-method-accordion {
    padding: 10px;
    border: 1px solid #707070;
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 500;
    box-shadow: unset!important;
}

.new-payment-method-accordion > .MuiAccordionSummary-root {
    padding: 0px;
    margin: 0!important;
    min-height: unset!important;
}

.new-payment-method-accordion .MuiAccordionSummary-root .Mui-expanded {
    min-height: unset!important;
    margin: 0;
}

.new-payment-method-accordion .MuiAccordionSummary-content {
    margin: 0px;
}

.new-payment-method-accordion .MuiIconButton-edgeEnd {
    margin: unset!important;
}

.new-payment-method-accordion .MuiIconButton-root {
    padding: 0px;
}

.new-payment-method-accordion .MuiAccordionDetails-root {
    margin: 0px;
    padding: 15px 0px 5px 0px;
    flex-direction: column;
}

.staff-img {
    border-radius: 50%;
    margin-right: 20px;
    vertical-align: middle;
}

.back-button {
    height: 90%;
    background-color: #ffffff;
    border-width: 0px;
    vertical-align: middle;
    cursor: pointer;
}

.info-panel-square-selected {
    height: 11px;
    width: 11px;
    display: inline-block;
    background-color: #192027;
    margin-right: 5px;
}

.info-panel-square-busy {
    height: 11px;
    width: 11px;
    display: inline-block;
    background-color: #f44336;
    margin-left: 10px;
    margin-right: 5px;
}

.info-panel-square-unavailable {
    height: 11px;
    width: 11px;
    display: inline-block;
    background-color: #cacccd;
    margin-left: 10px;
    margin-right: 5px;
}

span:before {
    content: '' !important;
}

/* Sign-in / sign-up page */

.sign-in-container {
    padding: 5px 13px;
    margin: 5px 15px;
    /*border: 2px solid #000000;*/
    border-radius: 4px;
}

.ws-sign-in-logo {
    display: block;
    margin: auto;
    margin-bottom: -8px;
}

.sign-in-input {
    font-family: 'Poppins';
    font-weight: 600;
}

.sign-in-button {
    background-color: #1ab394;
}

.sign-in-separator {
    display: flex;
    flex-direction: row;
    padding: 15px 0px;
}

/* Sign-in error */

.sign-in-error-container {
    background-color: #f44336;
    border-radius: 3px;
    margin-top: 10px;
    font-family: 'Poppins';
    display: flex;
    flex-direction: row;
    padding: 10px 15px;
    text-align: left;
}

.sign-in-error-label {
    color: #ffffff;
    align-items: center;
    display: flex;
    font-size: 14px;
}

.loading-container-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

/* Tel input custom styling */

.PhoneInputInput {
    font-family: 'Poppins';
    border-width: 0px 0px 1px 0px;
    border-bottom-color: #e3e3e3;
    height: 22px;
    padding: 6px 0 7px;
    font-size: 16px;
    color: #000000 !important;
    transition: border-bottom-color 0.5s;
    outline: 0!important;
}

.PhoneInputInput:focus {
    border-bottom-color: #000000;
    border-bottom-width: 2px;
}

.PhoneInputCountryIcon {
    border-radius: 3px;
}

.PhoneInputCountryIconImg {
    border-radius: 3px;
}

/* Confirmation page */

.user-left-mod{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: rgba(0,0,0,0.6);
    background-blend-mode: overlay;
}

.user-container{
    width: 42%;
    background-color: #ffffff;
    margin: 0 auto;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-bottom: 20px;
}

.user-container-sm{
    background-color: #ffffff;
    margin: 0 auto;
    border-radius: 5px;
    width: 75%;
    max-width: 385px;
    margin: 0px auto;
    margin-top: 50px;
}

.user-avatar-container{
    flex: 1;
    display: flex;
    justify-content: center;
    height: 60px;
}

.user-avatar{
    width: 80px;
    height: 80px;
    padding: 10px;
    border-radius: 50%;
    background-color: #192027;
    transform: translate3d(0,-50%,0);
    box-shadow: 0 5px 15px -8px rgba(0,0,0,.44), 0 8px 10px -5px rgba(0,0,0,.3);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-family: 'Poppins';
    font-weight: 300;
    font-size: 32px;
    letter-spacing: 1px;
}

.user-header {
    text-align: center;
    font-family: 'Poppins';
    font-size: 22px;
    font-weight: 600;
}

.user-body-item {
    padding: 0px 10px 10px 10px;
    font-size: 16px;
    font-family: 'Poppins';
    font-weight: 500;
    text-align: center;
}

.user-container-footer {
    margin: 0 auto;
    width: 42%;
    background-color: #ffffff;
    position: relative;
    border-top: 2px dashed #000000;
    text-align: center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-bottom: 20px;
}

.user-container-footer::before,
.user-container-footer::after {
  content: "";
  position: absolute;
  top: 0;
  border: 4px solid transparent;

  -webkit-transform: translateY(calc(-50% - 1px));
  -ms-transform: translateY(calc(-50% - 1px));
  -o-transform: translateY(calc(-50% - 1px));
  transform: translateY(calc(-50% - 1px));
}

.user-container-footer::before {
  left: 0;
  border-left: 7px solid #000000;
}

.user-container-footer::after {
  right: 0;
  border-right: 7px solid #000000;
}

@keyframes slide-down {
    80% {
      transform: perspective(100px) rotateX(2deg) translate3d(0, 0, 0);
    }
    100% {
        transform: perspective(100px) rotateX(0deg) translate3d(0, 0, 0);
    }
  }

.user-container-footer {
    background-color: #fff;
  
    transform-style: preserve-3d;
    transform-origin: top center;
    transform: perspective(500px) rotateX(-90deg) translate3d(0, 0, 0);
  
    animation: slide-down 0.4s 0s ease-out forwards;
  }

.user-container-footer-span {
    margin: 10px 15px;
}

.no-booking-results {
    text-align: center;
    position: relative;
    display: block;
    top: 50%;
    transform: translateY(-50%);
    font-family: 'Poppins';
    font-size: 16px;
}

.no-booking-results-container {
    max-width: 60%;
    margin: auto;
    margin-bottom: 15px;
    line-height: 26px;
}

.options-container {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 5px;
}

.booking-datetime {
    margin: 5px 0px;
    color: #707070;
    font-size: 13px;
    font-weight: 600;
}

.booking-deposit-note {
    font-size: 12px;
    text-align: left;
    margin: 10px 5px;
    color: #707070;
}

.terms-link:hover {
    text-decoration: underline;
}

.booking-event-container {
    margin: 10px;
    padding: 10px;
    min-height: 100px;
    border: 2px solid #aaaaaa;
    border-radius: 5px;
    font-family: 'Poppins';
    border-left: 6px solid #4caf50;
}

.booking-event-time {
    font-weight: 600;
    font-size: 13px;
}
.booking-event-date {
    font-weight: 400;
    display: inline-block;
}
.booking-event-service-title {
    font-size: 15px;
    font-weight: 500;
    margin-top: 5px;
}
.booking-event-service-info {
    font-size: 12px;
    font-weight: 400;
    margin-top: 2px;
}
.booking-event-footer {
    width: 100%;
}
.booking-event-price {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 600;
    font-size: 15px;
    line-height: 39px;
}
.booking-service-separator {
    border-bottom: 1px solid #dadada;
    margin: 8px 0px;
}

/* Payment methods */

.payment-method-container {
    border-radius: 3px;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #707070;
    font-size: 14px;
    cursor: pointer;
    transition: .2s;
    font-family: 'Poppins';
    font-weight: 500;
    align-items: center;
    margin-bottom: 10px;
}

.payment-method-container:hover {
    background-color: #eeeeee;
}

.payment-method-delete-container {
    margin-left: 5px;
    margin-bottom: 10px;
    background-color: #f44336;
    border-radius: 3px;
    width: 40px;
    display: flex;
    cursor: pointer;
}

.payment-method-delete-container:hover {
    background-color: #db2c20;
}

.payment-method-delete-icon {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.social-redirect-spinner-container {
    background-color: #ffffff;
    z-index: 2;
    border-radius: 10px;
    padding: 10px;
}